export default (data) => {
  return(
    {
      builderId: data._id ? data._id : "5fb2a5f3ea262c2e14e462ad",
      typeId: data.user ? "user" : "office",
      officeId: data.user ? data.user : data.office,
      primaryColor: data.primaryColor ? data.primaryColor : "#4bacdd",
      address: data.address ? data.address : "M. A. Pinzón 6893",
      email: data.email ? data.email : "xdspropiedades@gmail.com",
      facebook: data.facebook ? data.facebook : "https://facebook.com/xdspropiedades",
      twitter: data.twitter ? data.twitter : "https://twitter.com",
      instagram: data.instagram ? data.instagram : "https://www.instagram.com/xdspropiedades/",
      favicon: data.favicon ? data.favicon : require("../images/icon.png"),      
      lat: data.lat ? data.lat : "-33.410167",
      lng: data.lng ? data.lng : "-70.655265",
      logo: data.logo ? data.logo : require("../images/logo-light.png"),
      logoDark: data.logoDark ? data.logoDark : require("../images/logo-dark.png"),
      movil: data.movil ? data.movil : "+56 9 5954 4321",
      phone: data.phone ? data.phone : "+56 9 5954 4321",
      footerText: data.footerText ? data.footerText : "Siempre buscando la manera mas profesional de pasar al siguiente nivel",
      home:{
        hero: {
          background: data.home && data.home.hero && data.home.hero.background ? data.home.hero.background : require("../images/template-home-hero-background.jpg"),
          title: data.home && data.home.hero && data.home.hero.title ? data.home.hero.title : "XDS Propiedades <br/>  Cuéntanos qué necesitas, queremos escucharte"
        },        
        properties: {
          title: data.home && data.home.properties && data.home.properties.title ? data.home.properties.title : "Contamos con una selección exclusiva de propiedades.",
          maxProperties: data.home && data.home.properties && data.home.properties.maxProperties ? data.home.properties.maxProperties : 9,
          footer: data.home && data.home.properties && data.home.properties.footer ? data.home.properties.footer : "Estas son solo algunas de las propiedades que tenemos para ofrecerte",
          buttonText: data.home && data.home.properties && data.home.properties.buttonText ? data.home.properties.buttonText : "Ver más"
        },        
        about: {
          banner: {
            image: data.home && data.home.about && data.home.about.banner && data.home.about.banner.image ? data.home.about.banner.image : require("../images/template-home-about-hero-background.jpg"),
            title: data.home && data.home.about && data.home.about.banner && data.home.about.banner.title ? data.home.about.banner.title : "Su creadora es Ximena Dzazopulos, Ingeniero Comercial, que se introduce al corretaje de propiedades el año 2012.",
            subTitle: data.home && data.home.about && data.home.about.banner && data.home.about.banner.subTitle ? data.home.about.banner.subTitle : "Realizando curso inicial en ACOP, variados cursos de aprendizaje en empresas reconocidas en el rubro, con certificación de Nexxos y diplomado en corretaje de propiedades en la Universidad Federico Santa María y constante capacitacion.",
            buttonText: data.home && data.home.about && data.home.about.banner && data.home.about.banner.buttonText ? data.home.about.banner.buttonText : "Conózcanos"
          }
        },  
        services: {
          items: data.home && data.home.services && data.home.services.items.length ? data.home.services.items : [
            {
              title: "Compras",
              description: "Cuéntanos que buscas, si la quieres para invertir o formar tu hogar. Te apoyamos en la búsqueda y asesoramos en el financiamiento."
            },
            {
              title: "Venta",
              description: "Confíanos tu propiedad, nuestro equipo te ayudara a venderla en el menor tiempo y a un precio justo, estamos para asesorarte y ayudarte en la venta"
            },
            {
              title: "Administración",
              description: "Deja tus arriendos en nuestras manos, nos preocupamos de tu bien raiz como si fuera nuestro, buscamos el mejor arrendatario, que pague el arriendo y cuide tu bien raiz, tú solo te preocupas de recibir tu arriendo todos los meses."
            }
            
          ]
        },  
        reviews: {
          items: data.home && data.home.reviews && data.home.reviews.items.length ? data.home.reviews.items : [
            {
              id: null,
              review: "Ximena nos arrendó y administró un departamento en el que estuvimos casi 4 años con una gestión impecable de su parte y una disposición excelente a nuestras consultas o necesidades que tuvimos mientras vivimos en esa departamento. Doy a ciencia cierta mis recomendaciones a Ximena como una excelente profesional en el rubro de la inmobiliaria.",
              author: "Estanislao V."
            },
            {
              id: null,
              review: "Desde un comienzo se nota el profesionalismo en gestionar arriendos de mis propiedades, lo cual me da seguridad a quien se arrienda los pagos mes a mes. Gracias Ximena",
              author: "Waldo Manriquez"
            },
            {
              id: null,
              review: "Ximena es una profesional eficiente y respetuosa de los acuerdos. Cuando me ayudó a ubicar el arriendo se cumpleron las expectativas encontrando uno adecuado a mis necesidades. Cuando me tocó partir, cerramos la entrega sin inconveniente, quedan ambos satisfechos. La recomiendo para ayudar  en la búsqueda de la vivienda adecuada para cada uno.",
              author: "Pedro Araujp"
            },
            {
              id: null,
              review: "Excelente atención, responsabilidad, puntualidad y trasparencia. ",
              author: "Erwin Salinas"
            },
            {
              id: null,
              review: "Corredora muy cercana, amable y con conocimientos de la industria inmobiliaria. Muy responsable y cumple los compromisos que adquiere. ",
              author: "Nibaldo Flores"
            },
            {
              id: null,
              review: "Excelente persona, muy profesional y preocupada de su trabajo. Genera confianza. Una gran corredora de propiedades.",
              author: "Héctor Concha"
            },
            {
              id: null,
              review: "Ximena ha sido una corredora de propiedades muy profesional que me ha facilitado la gestión tanto en la mediación y asesoramiento entre el dueño del departamento que arriendo y yo. Respetuosa en todo ámbito y por sobre todo dedica tiempo necesario y facilita los tiempos con respecto a coordinar horarios y papeleo. Muy agradecida de su gestión. ",
              author: "Cristina Poblete"
            },
            {
              id: null,
              review: "Excelente corredora.  Muy buena gestión, es la tercera vez que utilizo sus servicios, para mí y también para mi familia y en todos ha brindado un servicio de gran calidad, la recomiendo.",
              author: "Paola Pasten Rojas"
            },
            {
              id: null,
              review: "Excelente experiencia con ximena .profesional en todo sentido de lo que involucra su trabajo. Responsable preocuoada y muy ejecutiva para  cualquier  situacion  que ocurra he  tenido la mejor experiencias de  la forma en que se empapa en su trabajó. ",
              author: "Cecilia Escobar"
            },
            {
              id: null,
              review: "Ximena fue, en todo momento, una profesional apoyadora, honesta y comprensiva. Sumamente flexible y con gran capacidad de adaptación al cliente. Altamente empática. La recomiendo una y mil veces.",
              author: "Felipe Toloza"
            },
            {
              id: null,
              review: "Mi experiencia con Ximena, fue increíble una corredora, súper atenta y eficaz en cada consulta. Mi recomendación 1000%. ",
              author: "Yujairis Pierre"
            }
          ]
        }                    
      },
      about:{
        hero: {
          background: data.about && data.about.hero && data.about.hero.background ? data.about.hero.background : require("../images/template-about-hero-background.jpg"),
          title: data.about && data.about.hero && data.about.hero.title ? data.about.hero.title : "Un equipo de profesionales especializados en el sector inmobiliario."
        },
        history: {
          background: data.about && data.about.history && data.about.history.background ? data.about.history.background : require("../images/template-about-history-background.jpg"),
          description: data.about && data.about.history && data.about.history.description ? data.about.history.description : "<p>Somos XDS Propiedades, una empresa con varios años de trayectoria en el dinámico mercado inmobiliario, reconocidos por un servicio altamente personalizado y eficiente.</p><p>Nuestra gran fortaleza es el profundo conocimiento comercial inmobiliario que nos permite agregar valor a todos los proyectos que emprendemos y servicios que entregamos.<p/>",
          title: data.about && data.about.history && data.about.history.title ? data.about.history.title : "Nuestra Historia"
        },
        description: {
          items: data.about && data.about.description && data.about.description.items.length ? data.about.description.items : [
            {
              title: "Misión",
              description: "XDS PROPIEDADES nace del crecimiento y desarrollo en el corretaje de propiedades desde 2012. Siempre buscando la manera mas profesional de pasar al siguiente nivel, su impulsadora y creadora se ha ido involucrando desde los distintos aspectos de la asesoría inmobiliaria, para entregar un servicio completo, profesional y con los mas altos estándares, al generar alianzas con los mejores profesionales del rubro Inmobiliario"
            }
          ]
        },
        stats: {
          proffesionals: data.about && data.about.stats && data.about.stats.proffesionals ? data.about.stats.proffesionals : 12,
          properties: data.about && data.about.stats && data.about.stats.properties ? data.about.stats.properties : 50,
          years: data.about && data.about.stats && data.about.stats.years ? data.about.stats.years : 20,
          transactions: data.about && data.about.stats && data.about.stats.transactions ? data.about.stats.transactions :50
        },
        team: {
          visible: true,
          items: data.about && data.about.team && data.about.team.items.length ? data.about.team.items : [
            {
              avatar: require("../images/template-team-member-1.jpg"),
              cv: "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
              email: "usuario1@example.com",
              fullName: "Usuario 1",
              phone: "+56 9 5555 5555"
            },
            {
              avatar: require("../images/template-team-member-2.jpg"),
              cv: "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca.",
              email: "usuario2@example.com",
              fullName: "Usuario 2",
              phone: "+56 9 5555 5555"
            },
            {
              avatar: require("../images/template-team-member-3.jpg"),
              cv: "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
              email: "usuario3@example.com",
              fullName: "Usuario 3",
              phone: "+56 9 5555 5555"
            },
            {
              //avatar: require("../images/template-team-member-4.jpg"),
              avatar: "",
              cv: "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
              email: "usuario4@example.com",
              fullName: "Usuario 4",
              phone: "+56 9 5555 5555"
            }                          
          ]
        },
        ubication: {
          title:  data.about && data.about.ubication && data.about.ubication.title ? data.about.ubication.title : "¿Necesitas vender, arrendar o comprar una propiedad? Somos tu socio perfecto"
        }
      },
      contact: {
        map: {
          title: data.contact && data.contact.map && data.contact.map.title ? data.contact.map.title : "Encuéntranos en Region metropolitana y IV Region La Serena y Coquimbo",
          subTitle: data.contact && data.contact.map && data.contact.map.subTitle ? data.contact.map.subTitle : "234"
        },
        title: data.contact && data.contact.title ? data.contact.title : "¿Dudas? ¿Consultas? Estamos aquí para ayudarlo.",
        subTitle: data.contact && data.contact.subTitle ? data.contact.subTitle : "Envienos un mensaje y uno de nuestros asesores se pondra en contacto a la brevedad"        
      },
    }
  )
}